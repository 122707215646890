// サイドバー
import React from 'react';
import { Link } from 'gatsby';
import AllCategory from './static-query/allcategory';
import { RectangleMiddle } from './adsense';

const SideBar = () => {
  const categoryList = AllCategory();

  return (
    <div>
      {/* Profile */}
      <div className="mb-8 border-solid border-2 border-gray-200">
        <div className="pl-1 py-2 text-xl border-solid border-b-2 border-gray-200">
          プロフィール
        </div>
        <div className="p-2">
          <div className="mb-3">
            <img src="/favicon.ico" alt="" className="w-10 mx-auto" />
          </div>
          <div className="mb-3 text-2xl font-bold text-center">
            Heisen(ハイゼン)
          </div>
          <div>
            <p>
              25歳プログラマー。基本web系が得意だが、その他も広くやっています。
            </p>
            <p>好きな言語はPythonです。</p>
            <p>最近、ランニングにハマっている。</p>
          </div>
          <div className="text-right">
            <a
              href="https://twitter.com/Heisen_Tech"
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 hover:text-blue-700"
            >
              Twitterはこちら
            </a>
          </div>
        </div>
      </div>
      {/* adsence */}
      <div className="mb-8">
        <RectangleMiddle />
      </div>
      {/* カテゴリ */}
      <div className="mb-8 border-solid border-2 border-gray-200">
        <div className="pl-1 py-2 text-xl border-solid border-b-2 border-gray-200">
          カテゴリ
        </div>
        <div className="p-2">
          {categoryList.map((category, i) => (
            <Link
              key={i}
              to={`/category/${category}`}
              className="flex hover:text-blue-300"
            >
              <span className="material-icons">folder_open</span>
              {category}
            </Link>
          ))}
        </div>
      </div>
      {/* adsence */}
      <div className="mb-8">
        <RectangleMiddle />
      </div>
    </div>
  );
};

export default SideBar;
