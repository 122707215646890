import React from 'react';

const Footer = () => {
  return (
    <footer className="absolute bottom-0 w-full h-50px bg-black">
      <div className="flex justify-center items-center h-full text-white text-sm">
        {/* © 2014-2020 heisen-tech.com Inc. */}
        &copy;&nbsp;2020&nbsp;heisen-tech.com&nbsp;Inc.
      </div>
    </footer>
  );
};

export default Footer;
