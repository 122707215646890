//広告
import React from 'react';

// レクタングル中
export const RectangleMiddle = props => {
  // ブログデータ
  let { ...attr } = props;

  return (
    <div {...attr}>
      {/* <img
        className="w-full"
        src="data:image/gif;base64,R0lGODlhLAH6AIAAAKqqqu3nvyH5BAAHAP8ALAAAAAAsAfoAAAL/hI+py+0Po5y02ouz3hyED4biSJbmiabqyrbuC8fyTM9Gjef6zvf+D1TdgsSi8YhMHofKpvMJjRKZ0qr1ir1Ss9yu91vbgsfkMllsTqvXTTT7DY/j3PK6/V6i4/d8uL4PGAj2J1hoKEV4qLholMj4CKnjGElZ6TJpmalJgrnpqdn5KRoZOmqqWHqqGpi66orX+iobFztrq1Z7qzuWu+vL1fsrXBU8bOxUfKy85LHsXJb8LM0TPW0d1nytjZi97d3W/S3eGD5u/lN9rg6Svq7e7m4OHy8+T+9tf6+dr2/N3y/tH0BnAgcqK2jQGMKEwhYy9OXwoa6IEm1RrCjrIkZX/xo3quro0RTIkKJGkvRk8iSocirppWxZ6SVMUixnvqtpUx7OnPV28sTn8+e+oEL9ES0a8ChSgkqXHmzqVCHUqA2nUoVo9erErFotcu2a8StYjmLHfixrViTatCXXskXp9u1KuUzpLpNpF1fcvDT5SvU7DC9gXnsHoyps2JDgxF0WM87i+LEWxJL7RK7MDXNYza8uc0ZG+XMdz6KTkC7NDLVa1aNOsw7i+jW60LLTxK5NjTbuM7p3D+rt28vt4NiIUxpuXAby5DCWM78E/Dmx6NKjOK8uhDp20NsVa+9u+jv41OMBXS8/4jz6EOrXf2jvHv56+ejpl7c/Hj94/d35b//3jx2A1QkoHYHPGcgcgskpaByDxDkYHIS+SbgbhbhZWBuGsmn4GoeseagaiKiJWBqJopn4GYqcqagZi5i5WBmMksn4GI2M2ZgYjobpOBiPgPnoF5B8CZkXkXYZSReScin5FpNsOZkWlGZJORaVYFnZFZZaaXkVl1R5GRWYTom5FJlImVkUmkKp+RObPLmZE5w2yTkTnTDZ2RKeKul5Ep8k+RkSoB4JuhGhGBlaEaISKfoQoww5mhCkBkk6EKUAWdoPpvpoeg+nLonn3myhwgLqqLmZaoen8ajqDqvruHoTqqOVKusctNZKA6zn6KoTrn7c6msMvI4zbE/BslHsN8k0AnXsGstu8+xQzeo1rW3AVrtCtNdoaxS20FzrLQrcTjNuUuES1kG66q7LbrvuvgtvvAgUAAA7"
        alt=""
      /> */}
    </div>
  );
};

// ビッグバナー
export const BigBanner = props => {
  // ブログデータ
  let { ...attr } = props;

  return (
    <div {...attr}>
      {/* <img
        className="w-full"
        src="data:image/gif;base64,R0lGODlh2AJaAIAAAKqqqu3nvyH5BAAHAP8ALAAAAADYAloAAAL/hI+py+0Po5y02ouz3rz7D4biSJbmiaZewLbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMZNECn1Kr1is1qt9yu9wv+SsPksvmMTqvX7LY7CnjL5/S6/Y7P65Pjvf8PGCg4SFh402eYqLjI2Oj4CAcpOUlZaXm5hoi5ydnp+QkqoxlKWmp6imo3msra6voKu7QaS1tre0s7i7vL2+srqfsrPExcPBdsnKy8zFyF3AwdLT2d80x9jZ29bK3d7f2dGwc+Tl7uym2err6uiM7+Dh9f5y5fb38PRo+/z9/fpO8voMCBPAASPIgwYQCDChs6vMfwocSJ6SJSvIgxm8WM/xw7bhPnMaTIbhtHmjwJqyTKlSxJqWwJM6allzJr2lxE86bOnX9y8vwJ9BjIoESLGvJpNKlSLkiXOn0KpSnUqVSNSK2KNWvBoVq7eo3K9avYsVbDkj2LdmvatWyrmW0LF+7VuHR5zq2Lt+bdvHxZ7u0LeOTfwIQ5Di6MeOLhxIwVLm4MeeDjyJT5Ta6Mud7lzJzZbe4Mutzn0KS9jS6N+trp1KyhrW4NO9nr2LSFza6Ne9ft3LzD9f6Nbzfw4aeEEz8Oyjjy5ZuUM39OyTn06Y6kU7+eyDr27YK0c/++xzv48arekj/vG736W+LXuz/T/r38fObn2+8U/77+K/n3+zoH+1+AmPQnYIFHEGhggkIgqGCDPTDoYIQ4QChhhTNQaGGGLqjAYYcefghiiCKOSGKJJp6IYooqAlAAADs="
        alt=""
      /> */}
    </div>
  );
};
