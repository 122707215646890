import { useStaticQuery, graphql } from 'gatsby';

// 全てのカテゴリ
const AllCategory = () => {
  // 全ブログデータでカテゴリのみを取得
  const { allMdx } = useStaticQuery(
    graphql`
      query allCagegory {
        allMdx {
          nodes {
            frontmatter {
              category
            }
          }
        }
      }
    `
  );

  // カテゴリ一覧を作成
  let blogDataList = allMdx.nodes;
  let categoryList = [];
  blogDataList.forEach(blogData => {
    let { category } = blogData.frontmatter;
    // カテゴリ データがあるなら配列に入れる
    if (category) {
      categoryList.push(category);
    }
  });
  // 重複しているカテゴリを消す
  categoryList = Array.from(new Set(categoryList));

  return categoryList;
};

export default AllCategory;
