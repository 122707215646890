// 各ページのタイトル
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PageTitle = props => {
  const { pageType } = props;
  // ページ種類によって出力を分ける
  switch (pageType) {
    case 'top':
      return <TopPage />;
    case 'category-tag':
      return <CategoryTag />;
    case 'blog-list-category':
      return <BlogListCategory />;
    case 'blog-list-tag':
      return <BlogListTag />;
    case 'search':
      return <Search />;
    case 'blog-post':
      return (
        <BlogPost
          alt={props.alt}
          fluid={props.fluid}
          heroImage={props.heroImage}
        />
      );
    case 'about':
      return <About />;
    case 'privacy-policy':
      return <PrivacyPolicy />;
    case 'contact':
      return <Contact />;
    case 'not-found':
      return <NotFound />;
    default:
      return <TopPage />;
  }
};

export default PageTitle;

// トップページ
const TopPage = () => {
  return (
    <>
      <div className="text-left font-bold text-5xl md:text-10xl text-blue-700">
        Heisen
      </div>
      <div className="text-center text-3xl md:text-6xl py-1 text-blue-300">
        HeisenのTechBlog
      </div>
      <div className="text-right font-bold text-5xl pb-2 md:pb-6 md:text-10xl text-blue-700">
        TechBlog
      </div>
    </>
  );
};

// カテゴリ・タグページ
const CategoryTag = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        Category&amp;Tag
      </div>
      <div className="text-left text-3xl md:text-6xl py-1 text-blue-300">
        カテゴリ&amp;タグ
      </div>
    </>
  );
};

// カテゴリブログリストページ
const BlogListCategory = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        Category
      </div>
      <div className="text-left text-3xl md:text-6xl ml-1/10 md:ml-1/5 py-1 text-blue-300">
        カテゴリ
      </div>
    </>
  );
};

// タグブログリストページ
const BlogListTag = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        Tag
      </div>
      <div className="text-left text-3xl md:text-6xl ml-1/10 md:ml-1/5 py-1 text-blue-300">
        タグ
      </div>
    </>
  );
};

// 検索ページ
const Search = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        Search
      </div>
      <div className="text-left text-3xl md:text-6xl ml-1/10 md:ml-1/5 py-1 text-blue-300">
        検索
      </div>
    </>
  );
};

// ブログページ
const BlogPost = props => {
  // 画像

  let gatsbyImageData = getImage(props.heroImage);
  return (
    <div className="text-center text-none">
      {props.heroImage && <GatsbyImage image={gatsbyImageData} />}
    </div>
  );
};

// このブログについて
const About = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        About
      </div>
      <div className="text-left text-3xl md:text-6xl ml-1/10 md:ml-1/5 py-1 text-blue-300">
        このブログについて
      </div>
    </>
  );
};

// プライバシーポリシー
const PrivacyPolicy = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        Privacy&amp;Policy
      </div>
      <div className="text-left text-3xl md:text-6xl py-1 text-blue-300">
        プライバシー&amp;ポリシー
      </div>
    </>
  );
};

// お問い合わせ
const Contact = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        Contact
      </div>
      <div className="text-left text-3xl md:text-6xl ml-1/10 md:ml-1/5 py-1 text-blue-300">
        お問い合わせ
      </div>
    </>
  );
};

// 404 NotFoundページ
const NotFound = () => {
  return (
    <>
      <div className="text-center font-bold text-5xl md:text-8xl lg:text-10xl text-blue-700">
        NotFound
      </div>
      <div className="text-left text-3xl md:text-6xl ml-1/10 md:ml-1/5 py-1 text-blue-300">
        404
      </div>
    </>
  );
};
