import React from 'react';
import Helmet from 'react-helmet';
import Header from './header';
import Footer from './footer';
import SideBar from './side-bar';
import PageTitle from './page-title';

const Template = props => {
  const { title, description, pageType, heroImage, children } = props;
  // 追加クラス
  let addClass = pageType ? ' ' + pageType : '';
  let className = 'lg:w-9/12 lg:mr-6' + addClass;

  return (
    <>
      <Helmet>
        <html lang="ja" />
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta description={description} />
      </Helmet>
      <Header />
      {/* ページタイトル */}
      <div className="mb-5 px-0 bg-blue-300 bg-opacity-15">
        <div className="max-w-screen-xl mx-auto">
          {pageType && <PageTitle pageType={pageType} heroImage={heroImage} />}
        </div>
      </div>
      {/* 中央にするため margin auto を使用しているので paddingによる空白 */}
      <div className="max-w-screen-xl mx-auto mb-20 px-5 lg:flex lg:justify-between">
        <div className={className}>{children}</div>
        <div className="hidden lg:block lg:w-3/12">
          <SideBar />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Template;
