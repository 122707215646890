// ヘッダー
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

const Header = () => {
  // useState
  const [spNavEl, setSpNavEl] = useState(null);
  const [searchBoxEl, setSearchBoxEl] = useState(null);
  const [spNavDispFlag, setSpNavDispFlag] = useState(false);
  const [searchBoxDispFlag, setSearchBoxDispFlag] = useState(false);

  // componentDidMount componentDidUpdate
  useEffect(() => {
    // []が無いと複数回動く
    let spNav = document.getElementById('sp-nav');
    let searchBox = document.getElementById('search-box');
    // 初めからアニメーションを入れるとページ読み込み時に発動するので
    spNav.classList.add('duration-200', 'ease-out');
    searchBox.classList.add('duration-200', 'ease-out');

    // elementDom設定
    setSpNavEl(spNav);
    setSearchBoxEl(searchBox);
  }, []);

  // sp用ナビ
  const navToggle = e => {
    // アイコン
    // 現在の表示状態によってアイコンを変える
    if (spNavDispFlag) {
      // 表示中 非表示にする
      e.target.textContent = 'menu';
    } else {
      // 非表示 表示する
      e.target.textContent = 'clear';
    }
    // 表示 非表示を切り替える
    setSpNavDispFlag(!spNavDispFlag);
    // 表示 非表示
    spNavEl.classList.toggle('transform');
    spNavEl.classList.toggle('translate-x-full');
    spNavEl.classList.toggle('invisible');
  };

  // 検索
  const searchToggle = e => {
    // アイコン
    // 現在の表示状態によってアイコンを変える
    if (searchBoxDispFlag) {
      // 表示中 非表示にする
      e.target.textContent = 'search';
    } else {
      // 非表示 表示する
      e.target.textContent = 'clear';
    }
    // 表示 非表示を切り替える
    setSearchBoxDispFlag(!searchBoxDispFlag);
    // 表示・非表示
    searchBoxEl.classList.toggle('transform');
    searchBoxEl.classList.toggle('-translate-y-50-px');
    searchBoxEl.classList.toggle('invisible');
  };

  return (
    // 影つける
    <header className="sticky top-0 z-40 shadow bg-white">
      <div className="max-w-screen-xl mx-auto px-2 flex justify-between lg:justify-start items-center min-h-50px bg-white">
        {/* ロゴ */}
        <Link className="block w-3/12 lg:mr-2 lg:w-auto z-30" to="/">
          <img src="/favicon.ico" alt="" />
        </Link>
        {/* ブログ名 */}
        <Link className="text-2xl" to="/">
          <span className="text-blue-300">Heisen</span>
          <span className="text-blue-700">TechBlog</span>
        </Link>
        {/* 検索・メニューボタン */}
        <div className="lg:ml-auto flex justify-end items-center">
          {/* pc メニュー */}
          <nav className="hidden lg:block">
            <ul className="flex">
              <li className="mr-3">
                <Link to="/" className="text-blue-300 hover:text-blue-700">
                  トップ
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  to="/category-tag"
                  className="text-blue-300 hover:text-blue-700"
                >
                  カテゴリ&amp;タグ
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  to="/contact"
                  className="text-blue-300 hover:text-blue-700"
                >
                  お問い合わせ
                </Link>
              </li>
              <li className="mr-3">
                <Link to="/about" className="text-blue-300 hover:text-blue-700">
                  このブログについて
                </Link>
              </li>
              <li className="mr-2">
                <Link
                  to="/privacy-policy"
                  className="text-blue-300 hover:text-blue-700"
                >
                  プライバシー&amp;ポリシー
                </Link>
              </li>
            </ul>
          </nav>
          {/* 検索 */}
          <div className="cursor-pointer">
            <button
              className="material-icons text-4xl mt-1 text-blue-300"
              onClick={searchToggle}
              onKeyDown={searchToggle}
            >
              search
            </button>
          </div>
          {/* sp メニューボタン */}
          <div className="cursor-pointer z-30 lg:hidden">
            <button
              className="material-icons text-4xl mt-1 text-blue-300"
              onClick={navToggle}
              onKeyDown={navToggle}
            >
              menu
            </button>
          </div>
        </div>
      </div>

      {/* 検索 */}
      <div
        id="search-box"
        className="bg-white transform -translate-y-50-px w-full min-h-50px
                                        invisible fixed -z-10
                                        border-solid border-b-2 border-blue-300"
      >
        <form
          action="/search/"
          method="get"
          className="max-w-screen-xl mx-auto flex justify-between items-center"
        >
          <input
            type="text"
            name="q"
            id=""
            className="w-10/12 h-50px p-2 outline-none"
            placeholder="Search"
          />
          <button
            type="submit"
            className="material-icons outline-none text-4xl text-blue-300"
          >
            search
          </button>
        </form>
      </div>
      {/* メニュー */}
      <div
        id="sp-nav"
        className="transform translate-x-full
                                    h-screen w-full top-0 z-20 bg-white fixed invisible"
      >
        <nav className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-5/6">
          <ul>
            <li className="border-solid border-b-2 border-gray-600">
              <Link to="/" className="text-blue-300 text-2xl block py-6">
                トップ
              </Link>
            </li>
            <li className="border-solid border-b-2 border-gray-600">
              <Link
                to="/category-tag"
                className="text-blue-300 text-2xl block py-6"
              >
                カテゴリ&amp;タグ一覧
              </Link>
            </li>
            <li className="border-solid border-b-2 border-gray-600">
              <Link to="/contact" className="text-blue-300 text-2xl block py-6">
                お問い合わせ
              </Link>
            </li>
            <li className="border-solid border-b-2 border-gray-600">
              <Link to="/about" className="text-blue-300 text-2xl block py-6">
                このサイトについて
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="text-blue-300 text-2xl block py-6"
              >
                プライバシー&amp;ポリシー
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
